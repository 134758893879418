import React from "react";
import { Col, Container, Row } from "reactstrap";

const style = {
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
}

const phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}

const Footer = () => {
  return (
    <React.Fragment>
      <div style={phantom}>
      <footer className="footer pt-2" style={style}>
        <Container fluid={true}>
          <Row>
          </Row>
        </Container>
      </footer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
