import React from 'react';
import { Redirect } from 'react-router-dom';
import Home from '../pages/Home';

const authProtectedRoutes = [
];

const publicRoutes = [
  {path: "/home/:qrData", component: Home},
  {path: "/home", component: Home},
  {path: "/:qrData", exact: true, component: ({match}) => <Redirect to={`/home/${match.params.qrData}`} />},
  {path: "/", exact: true, component: () => <Redirect to="/home"/>},

];

export {authProtectedRoutes, publicRoutes};
