import queryString from 'query-string'

export function isNullOrUndefined(obj) {
  try {
    if (obj === null || obj === undefined)
      return true;
    else
      return false;
  } catch (e) {
    return true;
  }
}

export function isNullOrUndefinedOrEmpty(obj) {
  if (obj === null || obj === undefined)
    return true;
  else {
    if (obj === '')
      return true

    if (Array.isArray(obj) && obj.length === 0)
      return true;

    if (obj instanceof Object && Object.keys(obj).length === 0) {
      return true;
    }
  }
  return false;
}

export function isNotNullOrUndefinedAndTrue(obj) {
  if (obj === null || obj === undefined)
    return false;
  else {
    if (obj) {
      return true
    } else {
      return false;
    }
  }
}

export function getISOdateStringWithSeconds(clientTimestamp) {
  if (clientTimestamp !== undefined && clientTimestamp !== null) {
    let dt = new Date(clientTimestamp)
    let time = dt.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true})
    return dt.toDateString().replace(' 20', ', 20') + ', ' + time
  }
}

export function getISOdateString(clientTimestamp) {
  if (clientTimestamp !== undefined && clientTimestamp !== null) {
    let dt = new Date(clientTimestamp)
    return dt.toDateString()
  }
}

export function getISOdateStringForRange(clientTimestamp) {
  if (clientTimestamp !== undefined && clientTimestamp !== null) {
    const startDate = getISOdateString(clientTimestamp[0])
    const endDate = getISOdateString(clientTimestamp[1])
    if (startDate === endDate) {
      return endDate
    } else {
      return startDate + ' - ' + endDate
    }

  }
}

export const addQuery = (props, key, value) => {
  let pathname = props.location.pathname;
  const values = queryString.parse(props.location.search);

  if (isNullOrUndefined(value) || value === 'undefined') {
    delete values[key]
  } else {
    values[key] = value
  }

  props.history.push({
    pathname: pathname,
    search: queryString.stringify(values)
  });
};
export const addQueryMap = (props, map) => {
  let pathname = props.location.pathname;
  const values = queryString.parse(props.location.search);
  if (!isNullOrUndefined(map)) {
    map.forEach((value, key) => {
      if (isNullOrUndefined(value) || value === 'undefined') {
        delete values[key]
      } else {
        values[key] = value
      }
    });
    let searchParams = queryString.stringify(values);

    props.history.push({
      pathname: pathname,
      search: searchParams
    });
  }
};

export function getPotencyMeasurement(type) {
  switch (type) {
    case 'preroll':
    case 'cartridge':
    case 'disposable':
    case 'flower':
    case 'resin':
    case 'rosin':
    case 'shatter':
    case 'wax':
    case 'hash':
    case 'kief':
      return '%';
    case 'oil':
      return ' mg/ml';
    case 'tea':
    case 'rtd':
    case 'powder':
    case 'liquid':
    case 'shot':
      return ' mg';
    case 'spray':
      return ' mg/spray';
    case 'capsule':
      return ' mg/capsule';
    case 'chocolate':
    case 'gummy':
    case 'mint':
    case 'baked':
      return ' mg/pack';
    default:
      return ' g'
  }
}

export function getProductMeasurement(type) {
  switch (type) {
    case 'baked':
    case 'tea':
    case 'mint':
    case 'gummy':
    case 'preroll':
    case 'cartridge':
    case 'disposable':
    case 'flower':
    case 'resin':
    case 'rozin':
    case 'shatter':
    case 'wax':
    case 'hash':
    case 'kief':
    case 'topical':
      return ' g';
    case 'oil':
    case 'spray':
    case 'powder':
    case 'shot':
    case 'liquid':
    case 'rtd':
      return ' ml';
    case 'capsule':
      return ' mg';
    case 'chocolate':
      return ' g';
    case 'accessory':
      return ' unit';
    default:
      break;
  }

  return ''
}

export function getTypeEquivalentReport(type) {
  switch (type) {
    case 'baked':
    case 'tea':
    case 'mint':
    case 'gummy':
    case 'powder':
    case 'shot':
    case 'liquid':
    case 'rtd':
    case 'chocolate':
      return 'Edibles'
    case 'preroll':
      return 'Pre-Rolls';
    case 'cartridge':
    case 'disposable':
      return 'Vapes';
    case 'flower':
      return 'Flower';
    case 'oil':
    case 'spray':
    case 'capsule':
    case 'resin':
    case 'rozin':
    case 'shatter':
    case 'wax':
    case 'hash':
    case 'kief':
      return 'Extracts';
    default:
      break;
  }

  return ''
}


export function getVariantString(variant, type) {
  let str = '';
  let flag = false;

  switch (type) {
    case 'baked':
    case 'chocolate':
    case 'mint':
    case 'gummy':
    case 'preroll':
    case 'tea':
    case 'powder':
    case 'shot':
    case 'liquid':
    case 'rtd':
      str = variant.portions + ' x ' + variant.quantity;
      break
    case 'capsule':
      str = variant.portions + ' CAPS';
      if (variant.quantity) {
        str += ' x ' + variant.quantity
      } else {
        flag = true;
      }
      break;
    default:
      if (!isNullOrUndefinedOrEmpty(variant.quantity)) {
        str = variant.quantity
      } else if (!isNullOrUndefinedOrEmpty(variant.portions)) {
        str = variant.portions
      }
  }

  if (!flag)
    str += getProductMeasurement(type)

  if (type === 'cartridge') {
    if (!isNullOrUndefinedOrEmpty(variant.flavor)) {
      str += ' (' + variant.flavor + ')';
    }
  }

  return str;
}

export const ORDERS_MAP = new Map();
ORDERS_MAP.set('placed', 'new');
ORDERS_MAP.set('ready', 'ready for pickup');
ORDERS_MAP.set('no_show', 'no_show');
ORDERS_MAP.set('user_cancelled', 'user_cancelled');
ORDERS_MAP.set('store_cancelled', 'store_cancelled');
ORDERS_MAP.set('paid', 'paid');

export const ORDERS_MAP_DELIVERY = new Map();
ORDERS_MAP_DELIVERY.set('placed', 'new');
ORDERS_MAP_DELIVERY.set('ready', 'out for delivery');
ORDERS_MAP_DELIVERY.set('complete', 'delivered');
ORDERS_MAP_DELIVERY.set('no_show', 'no_show');
ORDERS_MAP_DELIVERY.set('user_cancelled', 'user_cancelled');
ORDERS_MAP_DELIVERY.set('store_cancelled', 'store_cancelled');
ORDERS_MAP_DELIVERY.set('paid', 'paid');


const getTimezoneOffset = (timeZone, date = new Date()) => {
  const TimeZone =  timeZone || {timeZone: 'EST'};
  const tz = date.toLocaleString("en", {TimeZone, timeStyle: "long"}).split(" ").slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

  // return UTC offset in millis
  return offset;
}

export function dateToFormattedString(d, timezone = 'America/Toronto') {
  let yyyy = d.getFullYear().toString();
  let mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
  let dd = d.getDate().toString();
  let date = new Date();
  let utcOffset = Math.abs(getTimezoneOffset(timezone) / 3600000)
  date.setHours(utcOffset)
  let hour = date.getHours().toString().split(',');
  let result  = yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]) + ' ' + (hour[1] ? hour : "0" + hour[0]) +':00:00'
  return result;
};
