import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((route, idx) => {
          return(
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          );
        })}

        {authProtectedRoutes.map((route, idx) => (
          <AppRoute
            path={route.path}
            layout={HorizontalLayout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
