const INIT_STATE = {
  layoutType: "horizontal",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarType: "default",
  topbarTheme: "colored",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false
};

export const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_LAYOUT':
      return {
        ...state,
        layoutType: action.payload
      };
    case 'CHANGE_PRELOADER':
      return {
        ...state,
        isPreloader: action.payload
      };

    case 'CHANGE_LAYOUT_WIDTH':
      return {
        ...state,
        layoutWidth: action.payload
      };
    case 'CHANGE_SIDEBAR_THEME':
      return {
        ...state,
        leftSideBarTheme: action.payload
      };
    case 'CHANGE_SIDEBAR_TYPE':
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType
      };
    case 'CHANGE_TOPBAR_THEME':
      return {
        ...state,
        topbarTheme: action.payload
      };
    case 'TOGGLE_RIGHT_SIDEBAR':
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar
      };
    case 'SHOW_RIGHT_SIDEBAR':
      return {
        ...state,
        showRightSidebar: true
      };
    case 'HIDE_RIGHT_SIDEBAR':
      return {
        ...state,
        showRightSidebar: false
      };
    default:
      return state;
  }
};

