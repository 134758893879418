import * as types from './types'
import { isNullOrUndefined } from '../lib/helper'
import { bfetch } from '../lib/http'
import {
  LOGIN_URL,
  REGISTER_URL,
  STORAGE_AUTH_TOKEN_KEY,
  FORGOT_PASSWORD_URL,
  ACTIVATE_ACCOUNT_URL,
  RESET_PASSWORD
} from '../lib/constants'

export const doLogin = (email, password, rememberMe = false) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(LOGIN_URL, {
        method: 'POST',
        body: {username: email, password}
      }).then(token => {
        if (!isNullOrUndefined(token)) {
          dispatch(dispatchSaveUserAccessToken(token.id_token));
          dispatch(doLoginSuccess({token: token.id_token}));
          resolve(token.id_token);
        } else {
          throw Object.assign(new Error('Account not valid'), { code: 402 });
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doLoginBegin = payload => {
  return {
    type: types.LOGIN_BEGIN,
    payload,
  };
};

export const doLoginSuccess = response => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {response},
  };
};

export const doLoginFailure = error => {
  return {
    type: types.LOGIN_FAILURE,
    payload: error,
  };
};

export const doLogout = () => {
  return async dispatch => {
    dispatch(doLogoutSuccess({}));
  };
};

export const doLogoutBegin = payload => {
  return {
    type: types.LOGOUT_BEGIN,
    payload,
  };
};

export const doLogoutSuccess = res => {
  return {
    type: types.LOGOUT_SUCCESS,
    res
  };
};

export const doLogoutFailure = error => {
  return {
    type: types.LOGOUT_FAILURE,
    error
  };
};


export function dispatchSaveUserAccessToken(data, saveToStorage = true) {
  if (saveToStorage) {
    try {
      localStorage.setItem(STORAGE_AUTH_TOKEN_KEY, data);
    } catch (error) {
      //console.log('Local Storage error: ' + error.message);
    }
  }
  return {
    type: types.SAVE_USER_ACCESS_TOKEN,
    data
  };
}

export const navigateToLoginScreen = () => {
  return {
    type: types.NAVIGATE_TO_LOGIN_SCREEN
  };
};


export const activateAccount = (email, password, key) => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      bfetch(ACTIVATE_ACCOUNT_URL, {
        method: 'POST',
        body: {login: email, email: email, password: password, key: key}
      }).then(res => {
        dispatch(doLogin(email, password)).then(loginRes => {
          //console.log(loginRes)
          resolve(loginRes)
        }).catch(err => {
          //console.log(err)
          reject(err);
        })
      }).catch(err => {
        reject(err);
      })
    })
  }
}


export const registerUser = (email, password) => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      bfetch(REGISTER_URL, {
        method: 'POST',
        body: {login: email, email: email, password: password}
      }).then(res => {
        dispatch(doLogin(email, password)).then(loginRes => {
          //console.log(loginRes)
          resolve(loginRes)
        }).catch(err => {
          //console.log(err)
          reject(err);
        })
      }).catch(err => {
        reject(err);
      })
    })
  }
}

/** Forgot Password **/

export const forgotPassword = (email) => {
  return async dispatch => {
    dispatch(forgotPasswordBegin(email))
    fetch(FORGOT_PASSWORD_URL, {
      method: 'POST',
      body: email,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(res => {
      dispatch(forgotPasswordSuccess(res))
    }).catch(err => {
      dispatch(forgotPasswordFailure(err))
    })
  }
}
export const forgotPasswordReset = () => {
  return {
    type: types.REQUEST_PASSWORD_RESET,
  }
}
export const forgotPasswordBegin = payload => {
  return {
    type: types.REQUEST_PASSWORD_BEGIN,
    payload,
  }
}
export const forgotPasswordSuccess = res => {
  return {
    type: types.REQUEST_PASSWORD_SUCCESS,
    res
  }
}
export const forgotPasswordFailure = error => {
  return {
    type: types.REQUEST_PASSWORD_FAILURE,
    error
  }
}

export const resetPassword = (newPassword, key) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(RESET_PASSWORD, {
        method: 'POST',
        body: {newPassword, key}
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  }
}
