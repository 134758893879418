export const STORAGE_AUTH_TOKEN_KEY = '@CDA_auth_token';

function createApiCall(postUrl) {
  return process.env.REACT_APP_API_REST_ENDPOINT + postUrl;
}

export const LOGIN_URL = createApiCall('/authenticate');
export const REGISTER_URL = createApiCall('/register');
export const FORGOT_PASSWORD_URL =createApiCall('/account/reset-password/init');
export const ACTIVATE_ACCOUNT_URL = createApiCall('/register/scanner');
export const RESET_PASSWORD = createApiCall('/account/reset-password/finish');
