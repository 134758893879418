import React, { Component, createRef } from 'react'
import { Collapse } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
//i18n
import { withNamespaces } from 'react-i18next'
import LoadingBar from 'react-top-loading-bar'

class Navbar extends Component {
  loadingBar = createRef()

  constructor(props) {
    super(props);
    this.state = {
      activeTopLink: undefined,
      activeAnalyticLink: undefined
    };

    if (this.props.location.pathname.includes('/home')){
      this.state.activeTopLink = 'Home'
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(this.props.navigateToLoginFlag) !== JSON.stringify(nextProps.navigateToLoginFlag)) {
      this.props.history.push('/login');
      return false;
    }
    return true;
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className=" top-nav-bg">
            <div className='container'>
              <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link className={"nav-link dropdown-toggle arrow-none " + this.isActiveTopLink('Home')}
                            to="/home" onClick={() => {this.setState({activeTopLink: 'Home'})}}>
                        Home
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </nav>
            </div>

          </div>
          <div className='w-100'>
            <LoadingBar ref={this.loadingBar} className='position-relative overflow-hidden'/>
          </div>
        </div>


      </React.Fragment>
    );
  }

  isActiveTopLink (link) {
    return this.state.activeTopLink === link ? 'active' : ''
  }

  isActiveAnalyticLink (link) {
    return this.state.activeAnalyticLink === link ? 'activeAnalytics' : ''
  }
}

export default withRouter(withNamespaces()(Navbar));
