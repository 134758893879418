import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <React.Fragment>
      <header id="page-topbar" style={{height: "65px", background: "linear-gradient(to bottom, #7202BB, #3264F5)"}}>
        <Link to="/">
          <div style={{color: 'white', margin: "15px 0 0 20px", fontSize: "24px"}}>
          </div>
        </Link>
      </header>
    </React.Fragment>
  );
}
