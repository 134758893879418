import { api, action } from '../lib/actions'

// *** TRIALS *** //
export function actionFetchTrials() {
  return action('FETCH_TRIALS', `${api}/scanners`, {method: 'GET'});
}

// *** SWAB HISTORY *** //
export function actionFetchSwabHistory() {
  return action('FETCH_SWAB_HISTORY', `${api}/scanners/tests/history`, {method: 'GET'});
}

export function actionClearSwabHistory() { return { type: 'CLEAR_SWAB_HISTORY' }};

export const actionScanKit = (formValues) => {
  const options = {method: 'POST',
    body: {
      trialId: formValues.trialId,
      patientId: formValues.patientId,
      locationId: formValues.locationId,
      kitId: formValues.kitId
    }
  };
  return action('SCAN_KIT', `${api}/test/register`, options);
};

export const actionUpdateTest = (formValues) => {
  const options = {method: 'PATCH',
    body: {
      id: formValues.id,
      patientId: formValues.patientId || '',
      type: formValues.type
    }
  };
  return action('UPDATE_PSYCHEDELICS_TESTS', `${api}/test`, options);
};

export function actionDeleteTest(kitId) {
  return action('DELETE_TEST', `${api}/test/${kitId}`, {method: 'DELETE'});
};
