export const WSCONNECT_BEGIN = 'WSCONNECT_BEGIN';
export const WSCONNECT_SUCCESS = 'WSCONNECT_SUCCESS';
export const WSCONNECT_FAILURE = 'WSCONNECT_FAILURE';

export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SAVE_USER_ACCESS_TOKEN = 'SAVE_USER_ACCESS_TOKEN';

// Startup
export const ALL_STARTUP_INFO_LOADED = 'ALL_STARTUP_INFO_LOADED';

// Login Part
export const LOGIN_BEGIN   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_OTP_BEGIN   = 'LOGIN_OTP_BEGIN';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS';
export const LOGIN_OTP_FAILURE = 'LOGIN_OTP_FAILURE';
export const DO_LOGOUT = 'DO_LOGOUT';

export const REQUEST_PASSWORD_RESET   = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_BEGIN   = 'REQUEST_PASSWORD_BEGIN';
export const REQUEST_PASSWORD_SUCCESS = 'REQUEST_PASSWORD_SUCCESS';
export const REQUEST_PASSWORD_FAILURE = 'REQUEST_PASSWORD_FAILURE';

export const FETCH_CUSTOMER_DETAILS = 'FETCH_CUSTOMER_DETAILS'
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const STARTUP_BEGIN = 'STARTUP_BEGIN'
export const CUSTOMER_DETAILS_UPDATE = 'CUSTOMER_DETAILS_UPDATE'

// Login Part
export const AUTO_LOGIN_BEGIN   = 'AUTO_LOGIN_BEGIN';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE';

// Registration Part
export const REGISTER_USER_BEGIN   = 'REGISTER_BEGIN';
export const REGISTER_USER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_USER_END = 'REGISTER_USER_END';

// ADMIN
export const  ADMIN_FETCH_ALL_PRODUCTS_BEGIN ='ADMIN_FETCH_ALL_PRODUCTS_BEGIN';
export const  ADMIN_FETCH_ALL_PRODUCTS_SUCCESS ='ADMIN_FETCH_ALL_PRODUCTS_SUCCESS';
export const  ADMIN_FETCH_ALL_PRODUCTS__FROM_CACHE_SUCCESS ='ADMIN_FETCH_ALL_PRODUCTS__FROM_CACHE_SUCCESS';
export const  ADMIN_FETCH_ALL_PRODUCTS_FAILURE ='ADMIN_FETCH_ALL_PRODUCTS_FAILURE';

export const  ADMIN_ACTIVATE_DISABLE_PRODUCT_BEGIN ='ADMIN_ACTIVATE_DISABLE_PRODUCT_BEGIN';
export const  ADMIN_ACTIVATE_DISABLE_PRODUCT_SUCCESS ='ADMIN_ACTIVATE_DISABLE_PRODUCT_SUCCESS';
export const  ADMIN_ACTIVATE_DISABLE_PRODUCT_FAILURE ='ADMIN_ACTIVATE_DISABLE_PRODUCT_FAILURE';

export const  ADMIN_FETCH_ALL_ORDERS_BEGIN ='ADMIN_FETCH_ALL_ORDERS_BEGIN';
export const  ADMIN_FETCH_ALL_ORDERS_SUCCESS ='ADMIN_FETCH_ALL_ORDERS_SUCCESS';
export const  ADMIN_FETCH_ALL_ORDERS_FAILURE ='ADMIN_FETCH_ALL_ORDERS_FAILURE';

export const  ADMIN_UPDATE_ORDER_BEGIN ='ADMIN_UPDATE_ORDER_BEGIN';
export const  ADMIN_UPDATE_ORDER_SUCCESS ='ADMIN_UPDATE_ORDER_SUCCESS';
export const  ADMIN_UPDATE_ORDER_FAILURE ='ADMIN_UPDATE_ORDER_FAILURE';

export const  ADMIN_FETCH_STORE_INFO_BEGIN ='ADMIN_FETCH_STORE_INFO_BEGIN';
export const  ADMIN_FETCH_STORE_INFO_SUCCESS ='ADMIN_FETCH_STORE_INFO_SUCCESS';
export const  ADMIN_FETCH_STORE_INFO_FAILURE ='ADMIN_FETCH_STORE_INFO_FAILURE';

export const  ADMIN_FETCH_SETTINGS_GENERAL_BEGIN ='ADMIN_FETCH_SETTINGS_GENERAL_BEGIN';
export const  ADMIN_FETCH_SETTINGS_GENERAL_SUCCESS ='ADMIN_FETCH_SETTINGS_GENERAL_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_GENERAL_FAILURE ='ADMIN_FETCH_SETTINGS_GENERAL_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_GENERAL_BEGIN ='ADMIN_UPDATE_SETTINGS_GENERAL_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_GENERAL_SUCCESS ='ADMIN_UPDATE_SETTINGS_GENERAL_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_GENERAL_FAILURE ='ADMIN_UPDATE_SETTINGS_GENERAL_FAILURE';

export const  ADMIN_FETCH_SETTINGS_TERMS_BEGIN ='ADMIN_FETCH_SETTINGS_TERMS_BEGIN';
export const  ADMIN_FETCH_SETTINGS_TERMS_SUCCESS ='ADMIN_FETCH_SETTINGS_TERMS_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_TERMS_FAILURE ='ADMIN_FETCH_SETTINGS_TERMS_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_TERMS_BEGIN ='ADMIN_UPDATE_SETTINGS_TERMS_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_TERMS_SUCCESS ='ADMIN_UPDATE_SETTINGS_TERMS_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_TERMS_FAILURE ='ADMIN_UPDATE_SETTINGS_TERMS_FAILURE';

export const  ADMIN_FETCH_SETTINGS_NOTIFICATION_BEGIN ='ADMIN_FETCH_SETTINGS_NOTIFICATION_BEGIN';
export const  ADMIN_FETCH_SETTINGS_NOTIFICATION_SUCCESS ='ADMIN_FETCH_SETTINGS_NOTIFICATION_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_NOTIFICATION_FAILURE ='ADMIN_FETCH_SETTINGS_NOTIFICATION_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_NOTIFICATION_BEGIN ='ADMIN_UPDATE_SETTINGS_NOTIFICATION_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_NOTIFICATION_SUCCESS ='ADMIN_UPDATE_SETTINGS_NOTIFICATION_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_NOTIFICATION_FAILURE ='ADMIN_UPDATE_SETTINGS_NOTIFICATION_FAILURE';
export const  ADMIN_RESEND_VERIFICATION_EMAIL_BEGIN ='ADMIN_RESEND_VERIFICATION_EMAIL_BEGIN';
export const  ADMIN_RESEND_VERIFICATION_EMAIL_SUCCESS ='ADMIN_RESEND_VERIFICATION_EMAIL_SUCCESS';
export const  ADMIN_RESEND_VERIFICATION_EMAIL_FAILURE ='ADMIN_RESEND_VERIFICATION_EMAIL_FAILURE';

export const  ADMIN_FETCH_SETTINGS_POS_BEGIN ='ADMIN_FETCH_SETTINGS_POS_BEGIN';
export const  ADMIN_FETCH_SETTINGS_POS_SUCCESS ='ADMIN_FETCH_SETTINGS_POS_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_POS_FAILURE ='ADMIN_FETCH_SETTINGS_POS_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_POS_BEGIN ='ADMIN_UPDATE_SETTINGS_POS_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_POS_SUCCESS ='ADMIN_UPDATE_SETTINGS_POS_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_POS_FAILURE ='ADMIN_UPDATE_SETTINGS_POS_FAILURE';

export const  ADMIN_FETCH_SETTINGS_PLAN_BEGIN ='ADMIN_FETCH_SETTINGS_PLAN_BEGIN';
export const  ADMIN_FETCH_SETTINGS_PLAN_SUCCESS ='ADMIN_FETCH_SETTINGS_PLAN_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_PLAN_FAILURE ='ADMIN_FETCH_SETTINGS_PLAN_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_PLAN_BEGIN ='ADMIN_UPDATE_SETTINGS_PLAN_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_PLAN_SUCCESS ='ADMIN_UPDATE_SETTINGS_PLAN_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_PLAN_FAILURE ='ADMIN_UPDATE_SETTINGS_PLAN_FAILURE';

export const  ADMIN_FETCH_SETTINGS_IMAGES_BEGIN ='ADMIN_FETCH_SETTINGS_IMAGES_BEGIN';
export const  ADMIN_FETCH_SETTINGS_IMAGES_SUCCESS ='ADMIN_FETCH_SETTINGS_IMAGES_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_IMAGES_FAILURE ='ADMIN_FETCH_SETTINGS_IMAGES_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_IMAGES_BEGIN ='ADMIN_UPDATE_SETTINGS_IMAGES_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_IMAGES_SUCCESS ='ADMIN_UPDATE_SETTINGS_IMAGES_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_IMAGES_FAILURE ='ADMIN_UPDATE_SETTINGS_IMAGES_FAILURE';

export const  ADMIN_FETCH_IMAGES_BEGIN ='ADMIN_FETCH_IMAGES_BEGIN';
export const  ADMIN_FETCH_IMAGES_SUCCESS ='ADMIN_FETCH_IMAGES_SUCCESS';
export const  ADMIN_FETCH_IMAGES_FAILURE ='ADMIN_FETCH_IMAGES_FAILURE';
export const  ADMIN_DELETE_IMAGES_BEGIN ='ADMIN_DELETE_IMAGES_BEGIN';
export const  ADMIN_DELETE_IMAGES_SUCCESS ='ADMIN_DELETE_IMAGES_SUCCESS';
export const  ADMIN_DELETE_IMAGES_FAILURE ='ADMIN_DELETE_IMAGES_FAILURE';

export const  ADMIN_FETCH_SETTINGS_DELIVERY_BEGIN ='ADMIN_FETCH_SETTINGS_DELIVERY_BEGIN';
export const  ADMIN_FETCH_SETTINGS_DELIVERY_SUCCESS ='ADMIN_FETCH_SETTINGS_DELIVERY_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_DELIVERY_FAILURE ='ADMIN_FETCH_SETTINGS_DELIVERY_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_DELIVERY_BEGIN ='ADMIN_UPDATE_SETTINGS_DELIVERY_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_DELIVERY_SUCCESS ='ADMIN_UPDATE_SETTINGS_DELIVERY_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_DELIVERY_FAILURE ='ADMIN_UPDATE_SETTINGS_DELIVERY_FAILURE';

export const  ADMIN_FETCH_SETTINGS_EMBEDDED_MENU_BEGIN ='ADMIN_FETCH_SETTINGS_EMBEDDED_MENU_BEGIN';
export const  ADMIN_FETCH_SETTINGS_EMBEDDED_MENU_SUCCESS ='ADMIN_FETCH_SETTINGS_EMBEDDED_MENU_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_EMBEDDED_MENU_FAILURE ='ADMIN_FETCH_SETTINGS_EMBEDDED_MENU_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_EMBEDDED_MENU_BEGIN ='ADMIN_UPDATE_SETTINGS_EMBEDDED_MENU_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_EMBEDDED_MENU_SUCCESS ='ADMIN_UPDATE_SETTINGS_EMBEDDED_MENU_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_EMBEDDED_MENU_FAILURE ='ADMIN_UPDATE_SETTINGS_EMBEDDED_MENU_FAILURE';

export const  ADMIN_FETCH_SETUP_STORE_BEGIN ='ADMIN_FETCH_SETUP_STORE_BEGIN';
export const  ADMIN_FETCH_SETUP_STORE_SUCCESS ='ADMIN_FETCH_SETUP_STORE_SUCCESS';
export const  ADMIN_FETCH_SETUP_STORE_FAILURE ='ADMIN_FETCH_SETUP_STORE_FAILURE';
export const  ADMIN_UPDATE_SETUP_STORE_BEGIN ='ADMIN_UPDATE_SETUP_STORE_BEGIN';
export const  ADMIN_UPDATE_SETUP_STORE_SUCCESS ='ADMIN_UPDATE_SETUP_STORE_SUCCESS';
export const  ADMIN_UPDATE_SETUP_STORE_FAILURE ='ADMIN_UPDATE_SETUP_STORE_FAILURE';

export const  ADMIN_FETCH_SETTINGS_VIRTUAL_BUDTENDER_BEGIN ='ADMIN_FETCH_SETTINGS_VIRTUAL_BUDTENDER_BEGIN';
export const  ADMIN_FETCH_SETTINGS_VIRTUAL_BUDTENDER_SUCCESS ='ADMIN_FETCH_SETTINGS_VIRTUAL_BUDTENDER_SUCCESS';
export const  ADMIN_FETCH_SETTINGS_VIRTUAL_BUDTENDER_FAILURE ='ADMIN_FETCH_SETTINGS_VIRTUAL_BUDTENDER_FAILURE';
export const  ADMIN_UPDATE_SETTINGS_VIRTUAL_BUDTENDER_BEGIN ='ADMIN_UPDATE_SETTINGS_VIRTUAL_BUDTENDER_BEGIN';
export const  ADMIN_UPDATE_SETTINGS_VIRTUAL_BUDTENDER_SUCCESS ='ADMIN_UPDATE_SETTINGS_VIRTUAL_BUDTENDER_SUCCESS';
export const  ADMIN_UPDATE_SETTINGS_VIRTUAL_BUDTENDER_FAILURE ='ADMIN_UPDATE_SETTINGS_VIRTUAL_BUDTENDER_FAILURE';

export const  ADMIN_FETCH_VIRTUAL_BUDTENDER_BEGIN ='ADMIN_FETCH_VIRTUAL_BUDTENDER_BEGIN';
export const  ADMIN_FETCH_VIRTUAL_BUDTENDER_SUCCESS ='ADMIN_FETCH_VIRTUAL_BUDTENDER_SUCCESS';
export const  ADMIN_FETCH_VIRTUAL_BUDTENDER_FAILURE ='ADMIN_FETCH_VIRTUAL_BUDTENDER_FAILURE';

export const  REQUEST_A_MEETING_RESET ='REQUEST_A_MEETING_RESET';
export const  REQUEST_A_MEETING_BEGIN ='REQUEST_A_MEETING_BEGIN';
export const  REQUEST_A_MEETING_SUCCESS ='REQUEST_A_MEETING_SUCCESS';
export const  REQUEST_A_MEETING_FAILURE ='REQUEST_A_MEETING_FAILURE';

export const  REQUEST_UPGRADE_RESET ='REQUEST_UPGRADE_RESET';
export const  REQUEST_UPGRADE_BEGIN ='REQUEST_UPGRADE_BEGIN';
export const  REQUEST_UPGRADE_SUCCESS ='REQUEST_UPGRADE_SUCCESS';
export const  REQUEST_UPGRADE_FAILURE ='REQUEST_UPGRADE_FAILURE';

export const  NEW_USER_STEP = 'NEW_USER_STEP';
export const  NAVIGATE_TO_LOGIN_SCREEN ='NAVIGATE_TO_LOGIN_SCREEN';

export const  ADMIN_FETCH_CATEGORIES_BEGIN ='ADMIN_FETCH_CATEGORIES_BEGIN';
export const  ADMIN_FETCH_CATEGORIES_SUCCESS ='ADMIN_FETCH_CATEGORIES_SUCCESS';
export const  ADMIN_FETCH_CATEGORIES_FAILURE ='ADMIN_FETCH_CATEGORIES_FAILURE';
export const  ADMIN_FETCH_CATEGORY_STORES_BEGIN ='ADMIN_FETCH_CATEGORY_STORES_BEGIN';
export const  ADMIN_FETCH_CATEGORY_STORES_SUCCESS ='ADMIN_FETCH_CATEGORY_STORES_SUCCESS';
export const  ADMIN_FETCH_CATEGORY_STORES_FAILURE ='ADMIN_FETCH_CATEGORY_STORES_FAILURE';
export const  ADMIN_CREATE_CATEGORY_STORE_BEGIN ='ADMIN_CREATE_CATEGORY_STORE_BEGIN';
export const  ADMIN_CREATE_CATEGORY_STORE_SUCCESS ='ADMIN_CREATE_CATEGORY_STORE_SUCCESS';
export const  ADMIN_CREATE_CATEGORY_STORE_FAILURE ='ADMIN_CREATE_CATEGORY_STORE_FAILURE';
export const  ADMIN_UPDATE_CATEGORY_STORE_BEGIN ='ADMIN_UPDATE_CATEGORY_STORE_BEGIN';
export const  ADMIN_UPDATE_CATEGORY_STORE_SUCCESS ='ADMIN_UPDATE_CATEGORY_STORE_SUCCESS';
export const  ADMIN_UPDATE_CATEGORY_STORE_FAILURE ='ADMIN_UPDATE_CATEGORY_STORE_FAILURE';
export const  ADMIN_DELETE_CATEGORY_STORE_BEGIN ='ADMIN_DELETE_CATEGORY_STORE_BEGIN';
export const  ADMIN_DELETE_CATEGORY_STORE_SUCCESS ='ADMIN_DELETE_CATEGORY_STORE_SUCCESS';
export const  ADMIN_DELETE_CATEGORY_STORE_FAILURE ='ADMIN_DELETE_CATEGORY_STORE_FAILURE';
export const  ADMIN_FETCH_CATEGORY_STORE_PRODUCTS_BEGIN ='ADMIN_FETCH_CATEGORY_STORE_PRODUCTS_BEGIN';
export const  ADMIN_FETCH_CATEGORY_STORE_PRODUCTS_SUCCESS ='ADMIN_FETCH_CATEGORY_STORE_PRODUCTS_SUCCESS';
export const  ADMIN_FETCH_CATEGORY_STORE_PRODUCTS_FAILURE ='ADMIN_FETCH_CATEGORY_STORE_PRODUCTS_FAILURE';
export const  ADMIN_CREATE_CATEGORY_STORE_PRODUCT_BEGIN ='ADMIN_CREATE_CATEGORY_STORE_PRODUCT_BEGIN';
export const  ADMIN_CREATE_CATEGORY_STORE_PRODUCT_SUCCESS ='ADMIN_CREATE_CATEGORY_STORE_PRODUCT_SUCCESS';
export const  ADMIN_CREATE_CATEGORY_STORE_PRODUCT_FAILURE ='ADMIN_CREATE_CATEGORY_STORE_PRODUCT_FAILURE';
export const  ADMIN_DELETE_CATEGORY_STORE_PRODUCT_BEGIN ='ADMIN_DELETE_CATEGORY_STORE_PRODUCT_BEGIN';
export const  ADMIN_DELETE_CATEGORY_STORE_PRODUCT_SUCCESS ='ADMIN_DELETE_CATEGORY_STORE_PRODUCT_SUCCESS';
export const  ADMIN_DELETE_CATEGORY_STORE_PRODUCT_FAILURE ='ADMIN_DELETE_CATEGORY_STORE_PRODUCT_FAILURE';

/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
