export const actionModals = (modals) => ({
  type: 'MODALS',
  payload: modals
});

export const actionLocations = (trial, locations) => ({
  type: 'LOCATIONS',
  trial: trial,
  locations: locations
});
