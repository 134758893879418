import createReducer from './createReducer'
import * as types from '../actions/types'

export const Startup = createReducer({
	network: false,
	autoLogin: undefined,
	error: undefined
}, {
	[types.WSCONNECT_SUCCESS](state, action) {
		return {
			...state,
			network: true
		}
	}
});


export const NetWork = createReducer({
	host: undefined,
	connected: false,
	err: undefined
}, {
	[types.WSCONNECT_BEGIN](state, action) {
		return {
			...state,
			host: action.payload.host,
			connected: false,
			err: undefined
		}
	}, [types.WSCONNECT_SUCCESS](state, action) {
		return {
			...state,
			host: action.payload.host,
			connected: true,
			err: undefined
		}
	}, [types.WSCONNECT_FAILURE](state, action) {
		return {
			...state,
			connected: false,
			err: action
		}
	},
});
