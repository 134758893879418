import { bfetch } from './http'

export const api = process.env.REACT_APP_API_REST_ENDPOINT;

export const action = (label, url, options) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(
        (() => {
          return {type: `${label}_BEGIN`, payload: {}};
        })()
      );
      bfetch(url, options)
      .then(res => {
        dispatch(
          (() => {
            return { type: `${label}_SUCCESS`, payload: res };
          })()
        );
        resolve(res);
      })
      .catch(err => {
        dispatch(
          (() => {
            return { type: `${label}_FAILURE`, payload: err };
          })()
        );
        reject(err);
      })
    })
  };
}
