import {store} from '../store/store';
import jwtDecode from 'jwt-decode';
import {doLogoutSuccess, navigateToLoginScreen} from "../actions/auth";

let defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

let userTokens = null;

store.subscribe(() => {
  userTokens = store.getState().UserAccessToken;
  if(userTokens && userTokens.token && userTokens.token.length > 0) userTokens = userTokens.token;
  defaultHeaders['Authorization'] = userTokens ? ('Bearer ' + userTokens) : '';
});

export const bfetch = async (url, options) => {
  if (!options) {
    options = {};
  }

  try {
    if (userTokens) {
      const decodedJwt = jwtDecode(userTokens);
      if (decodedJwt.exp * 1000 < (new Date()).getTime()) {
        store.dispatch(doLogoutSuccess({}));
        store.dispatch(navigateToLoginScreen())
        return null;
      }
    }
  } catch (e) {

  }


  // check if token has been expired

  if (!options.method) {
    options.method = options.method || 'GET';
  }

  options.headers = Object.assign({}, defaultHeaders, options.headers);

  if (typeof (options.body) !== 'string') {
    options.body = JSON.stringify(options.body);
  }

  return fetch(url, options)
    .then(response => {
      if(response.ok) return response.text();
      else return response.json().then(json => {throw json});
    })
    .then((data) => {
      return data ? JSON.parse(data) : {};
    })
    .catch((error) => {
      throw error;
    })
}
