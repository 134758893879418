import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QrReader from 'modern-react-qr-reader'
import { Button } from 'reactstrap';
import rotateCameraSvg from '../assets/images/icons/camera-reverse.svg';
import logo from '../assets/images/halugen-social-logo.png';

//example qrData: 8b19a49300
export default function Home() {
  const { qrData } = useParams();
  const [scanData, setScanData] = useState('loading');
  useEffect(() => { if(qrData && qrData.length == 10) {setScanData(qrData)} else {return} }, []);
  if(scanData === "loading") {
    if(!qrData) setScanData("");
    return <></>;
  }
  else return (
    <>
      <div className="text-center bg-white h-100">
        { scanData
          ? <GeneticsReportSummary scanData={scanData} setScanData={setScanData} />
          : <QrScanner setScanData={setScanData} />
        }
      </div>
    </>
  );
}

function QrScanner(props) {
  const {setScanData} = props;
  const [facingmode, setFacingmode] = useState("environment");
  return (
    <div className="pt-5">
      <h1>Scan Genetic Test</h1>
      <div style={{width: 300, display: "inline-block"}}>
        <Button
          onClick={() => facingmode === "environment" ? setFacingmode("user") : setFacingmode("environment") }
          className="w-100 mt-3"
          style={{backgroundColor: "grey", fontSize: "18px", color: "white"}}
        ><img src={rotateCameraSvg} style={{width: "24px", margin: "0 10px 4px 0"}} />Switch Camera</Button>
        <QrReader
          delay={500}
          onError={e => e}
          facingMode={facingmode}
          onScan={(data) => {
            if(data) {
              const newData = data.replace("https://report.halugen.com/", "");
              setScanData(newData);
            }
          }}
        />
      </div>
      <h4 className="my-3 pb-5">Center QR code to scan</h4>
    </div>
  )
}

function GeneticsReportSummary(props) {
  const {scanData, setScanData} = props;
  if(!(scanData && scanData.length == 10)) return <></>;
  if(scanData == "0000000000") return <>No test result data</>;

  // Decode binary encoded QR string
  const charLookup = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',''];
  const c4aLookup = ['PENDING', 'INCONCLUSIVE', 'ZERO', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'];
  const cyp2b6Lookup = ['PENDING', 'INCONCLUSIVE', '1_1', '1_6', '6_6'];
  const cyp2d6Lookup = ['PENDING', 'NO_RESULT', 'POOR', 'INTERMEDIATE', 'NORMAL', 'ULTRARAPID'];
  const disc1Lookup = ['PENDING', 'INCONCLUSIVE', 'A_A', 'A_T', 'T_T'];
  const htr2aLookup = ['PENDING', 'INCONCLUSIVE', 'C_C', 'C_T', 'T_T'];
  const bdnfLookup = ['PENDING', 'INCONCLUSIVE', 'VAL_VAL', 'VAL_MET', 'MET_MET'];
  const nrg1Lookup = ['PENDING', 'INCONCLUSIVE', 'C_C', 'C_T', 'T_T'];

  const binString1 = (parseInt(scanData.substring(0,8), 16) >>> 0).toString(2).padStart(32, "0");
  const binString2 = (parseInt(scanData.substring(8), 16) >>> 0).toString(2).padStart(8, "0");
  const binString = binString1 + binString2;
  const initials = charLookup[parseInt(binString.substring(0,5), 2)] + charLookup[parseInt(binString.substring(5,10), 2)];
  const numberOfMarkers = parseInt(binString.substring(10,14), 2);
  const c4a = c4aLookup[parseInt(binString.substring(14,17), 2)];
  const cyp2b6 = cyp2b6Lookup[parseInt(binString.substring(17,20), 2)];
  const cyp2d6 = cyp2d6Lookup[parseInt(binString.substring(20,23), 2)];
  const disc1 = disc1Lookup[parseInt(binString.substring(23,26), 2)];
  const htr2a = htr2aLookup[parseInt(binString.substring(26,29), 2)];
  const bdnf = bdnfLookup[parseInt(binString.substring(29,32), 2)];
  const nrg1 = nrg1Lookup[parseInt(binString.substring(32,35), 2)];
  const testType = parseInt(binString.substring(35), 2);
  if(testType != 0) return <>Invalid test results type</>;
  if(numberOfMarkers != 7) return <>Incorrect number of markers in this test result set</>;

  let result1 = {resultSensitivity: undefined, resultSSRI: undefined};
  let result2 = null;
  let result3 = null;
  let result4 = null;
  let result5 = null;
  let result6 = null;
  let result7 = null;
  let colour1 = "#888888";
  let colour2 = "#888888";
  let colour3 = "#888888";
  let colour4 = "#888888";
  let colour5 = "#888888";
  let colour6 = "#888888";
  let colour7 = "#888888";

  const greenColour = "#28A745";
  const yellowColour = "#EFA31D";
  const redColour = "#DC3280";
  const pendingColour = "#888888";
  const inconclusiveColour = "#888888";
  const greyTextColour = "#888888";

  switch (htr2a) {
    case ('C_C'):
      result1 = {resultSensitivity: 'Reduced', resultSSRI: 'Increased'};
      colour1 = greenColour;
      break;
    case ('C_T'):
      result1 = {resultSensitivity: 'Normal', resultSSRI: 'Normal'}
      colour1 = greenColour;
      break;
    case ('T_T'):
      result1 = {resultSensitivity: 'Increased', resultSSRI: 'Normal'}
      colour1 = redColour;
      break;
    case ('INCONCLUSIVE'):
      result1 = {resultSensitivity: 'Inconclusive', resultSSRI: 'Inconclusive'}
      break;
    case ('PENDING'):
      result1 = {resultSensitivity: 'NO RESULT', resultSSRI: 'NO RESULT'}
      break;
  }
  switch (cyp2b6) {
    case ('1_1'):
      result2 = 'Normal'
      colour2 = greenColour;
      break;
    case ('1_6'):
      result2 = 'Slow'
      colour2 = yellowColour;
      break;
    case ('6_6'):
      result2 = 'Very Slow'
      colour2 = redColour;
      break;
    case ('INCONCLUSIVE'):
      result2 = 'Inconclusive'
      break;
    case ('PENDING'):
      result2 = 'NO RESULT'
      break;
  }
  switch (c4a) {
    case ('ZERO'):
      result3 = 'Normal'
      colour3 = greenColour;
      break;
    case ('ONE'):
      result3 = 'Normal'
      colour3 = greenColour;
      break;
    case ('TWO'):
      result3 = 'Normal'
      colour3 = greenColour;
      break;
    case ('THREE'):
      result3 = 'Normal'
      colour3 = greenColour;
      break;
    case ('FOUR'):
      result3 = 'Increased'
      colour3 = redColour;
      break;
    case ('FIVE'):
      result3 = 'Increased'
      colour3 = redColour;
      break;
    case ('INCONCLUSIVE'):
      result3 = 'Inconclusive'
      break;
    case ('PENDING'):
      result3 = 'NO RESULT'
      break;
  }
  switch (nrg1) {
    case ('C_C'):
      result4 = 'Normal'
      colour4 = greenColour;
      break;
    case ('C_T'):
      result4 = 'Normal'
      colour4 = greenColour;
      break;
    case ('T_T'):
      result4 = 'Increased'
      colour4 = redColour;
      break;
    case ('INCONCLUSIVE'):
      result4 = 'Inconclusive'
      break;
    case ('PENDING'):
      result4 = 'NO RESULT'
      break;
  }
  switch (disc1) {
    case ('A_A'):
      result5 = 'Normal'
      colour5 = greenColour;
      break;
    case ('A_T'):
      result5 = 'Normal'
      colour5 = greenColour;
      break;
    case ('T_T'):
      result5 = 'Increased'
      colour5 = redColour;
      break;
    case ('INCONCLUSIVE'):
      result5 = 'Inconclusive'
      break;
    case ('PENDING'):
      result5 = 'NO RESULT'
      break;
  }
  switch (cyp2d6) {
    case ('POOR'):
      result6 = 'Very Slow';
      colour6 = redColour;
      break;
    case ('INTERMEDIATE'):
      result6 = 'Normal';
      colour6 = greenColour;
      break;
    case ('NORMAL'):
      result6 = 'Normal';
      colour6 = greenColour;
      break;
    case ('ULTRARAPID'):
      result6 = 'Increased';
      colour6 = greenColour;
      break;
    case ('NO_RESULT'):
      result6 = 'NO RESULT';
      break;
    case ('PENDING'):
        result6 = 'NO RESULT';
    case (null):
      result6 = 'NO RESULT';
      break;
    default:
      result6 = 'NO RESULT'
  }
console.log("bdnf", bdnf);
  switch (bdnf) {
    case ('VAL_VAL'):
      result7 = 'Normal'
      colour7 = greenColour;
      break;
    case ('VAL_MET'):
      result7 = 'Decreased'
      colour7 = yellowColour;
      break;
    case ('MET_MET'):
      result7 = 'Very Decreased'
      colour7 = redColour;
      break;
    case ('INCONCLUSIVE'):
      result7 = 'Inconclusive'
      break;
    case ('PENDING'):
      result7 = 'NO RESULT'
      break;
  }

  return (
    <div className="p-3 text-left" style={{maxWidth: "600px", display: "inline-block"}}>

      <div className="row no-gutters mb-4">
        <div className="col-2">
          <img src={logo} width={50} />
        </div>
        <div className="col-10">
          <h4>PSYCHEDELICS GENETIC TEST</h4>
          <h5 style={{color: greyTextColour}}>Report Summary ({initials})</h5>
        </div>
      </div>

      <div className="p-2 mb-3 text-center" style={{color: greyTextColour, border: "1px solid #888888", fontSize: "18px"}}>
        PHARMACODYNAMIC GENES
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">Serotonin Sensitivity</h5>
            <h5 style={{color: greyTextColour}}>HTR2A</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
          <div className="" style={{height: 25, width: 25, backgroundColor: colour1, borderRadius: "50%", display: "inline-block"}}></div>
          <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
            <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result1.resultSensitivity}</h5>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">Ketamine Response</h5>
            <h5 style={{color: greyTextColour}}>BDNF</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
          <div className="" style={{height: 25, width: 25, backgroundColor: colour7, borderRadius: "50%", display: "inline-block"}}></div>
          <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
            <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result7}</h5>
          </div>
        </div>
      </div>

      <div className="p-2 mb-3 text-center" style={{color: greyTextColour, border: "1px solid #888888", fontSize: "18px"}}>
        PHARMACOKINETIC GENES
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">Ketamine Metabolism</h5>
            <h5 style={{color: greyTextColour}}>CYP2B6</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
          <div className="pt-3" style={{height: 25, width: 25, backgroundColor: colour2, borderRadius: "50%", display: "inline-block"}} />
          <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
            <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result2}</h5>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">LSD, MDMA, Ayahuasca Metabolism</h5>
            <h5 style={{color: greyTextColour}}>CYP2D6</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
          <div className="pt-3" style={{height: 25, width: 25, backgroundColor: colour6, borderRadius: "50%", display: "inline-block"}}></div>
          <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
            <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result6}</h5>
          </div>
        </div>
      </div>

      <div className="p-2 mb-3 text-center" style={{color: greyTextColour, border: "1px solid #888888", fontSize: "18px"}}>
        MENTAL HEALTH RISK GENES
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">Risk Gene</h5>
            <h5 style={{color: greyTextColour}}>C4A</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
          <div className="pt-3" style={{height: 25, width: 25, backgroundColor: colour3, borderRadius: "50%", display: "inline-block"}}></div>
          <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
            <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result3}</h5>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">Risk Gene</h5>
            <h5 style={{color: greyTextColour}}>NRG1</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
            <div className="pt-3" style={{height: 25, width: 25, backgroundColor: colour4, borderRadius: "50%", display: "inline-block"}}></div>
            <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
              <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result4}</h5>
            </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 pr-0">
          <div>
            <h5 className="mb-0">Risk Gene</h5>
            <h5 style={{color: greyTextColour}}>DISC1</h5>
          </div>
        </div>
        <div className="col-6 pl-2" style={{whiteSpace: "nowrap"}}>
            <div className="pt-3" style={{height: 25, width: 25, backgroundColor: colour5, borderRadius: "50%", display: "inline-block"}}></div>
            <div style={{color: greyTextColour, display: "inline-block", position: "relative", top: -7}}>
              <h5 className="ml-2 pb-3" style={{color: greyTextColour}}>{result5}</h5>
            </div>
        </div>
      </div>

      <div className="text-center">
        <button
          className="btn btn-outline-primary btn-lg border rounded"
          type="button"
          data-dismiss="modal"
          onClick={() => setScanData('')}
        >SCAN A NEW REPORT</button>
      </div>
    </div>
  )
}

function returnResultColor(resultSensitivity) {
  switch (resultSensitivity && resultSensitivity) {
    case 'NO RESULT':
      return 'text-secondary'
    default:
      return 'var(--genetics-primary)'
  }
}
