import {combineReducers} from 'redux'
import * as ReportReducer from './report'
import * as LayoutReducer from './layout'
import * as StartupReducers from './startup'

export default combineReducers(
	Object.assign(
    StartupReducers,
		LayoutReducer,
    ReportReducer
	)
);
